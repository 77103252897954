import * as React from "react"
import Layout from "../../components/layout"
import '../../style.css'
import Star from "../../components/star";
import Vid from "../../components/video";
import Accordion from 'react-bootstrap/Accordion';

const MonsterBlackhole = () => {

    const info={
        video:true,
        title:"Mini-Jet near Black Hole",
        link:'https://www.youtube.com/embed/zxqQ4G0NOhI',
        description:'Our Milky Way’s central black hole has a leak! This supermassive black hole, over 4 million times more massive than our Sun, looks like it still has the remnants of a blowtorch-like jet dating back several thousand years.',
        internalText:`Our Milky Way’s central black hole has a leak! This supermassive black hole, over 4 million times more massive than our Sun, looks like it still has the remnants of a blowtorch-like jet dating back several thousand years. 
        NASA’s Hubble Space Telescope hasn’t photographed the phantom jet yet, but it has helped find circumstantial evidence that the jet is still pushing feebly into a huge hydrogen cloud.`,
        transcript:[
            `Our Milky Way’s central black hole has a leak!
            This supermassive black hole, over 4 million times more massive than our Sun, looks like it still has the remnants of a blowtorch-like
            jet dating back several thousand years.
            NASA’s Hubble Space Telescope hasn’t photographed the phantom jet yet,
            but it has helped find circumstantial evidence that the jet is still
            pushing feebly into a huge hydrogen cloud.
            This is further evidence that the black hole is not a sleeping monster, but periodically
            hiccups as stars and gas clouds fall into it. The hiccup results in superheated material
            blasting away from the black hole as narrow beams, or jets, shooting in the same direction
            as the black hole’s spin axis along with a flood of ionizing radiation.
            As the jet blows through the gas, it hits material, which creates a series of expanding
            bubbles that extend out to at least 500 light-years.
            The streams continue to percolate out of the
            Milky Way’s dense gas disk into the galactic halo.
            Scientists concluded that this black hole clearly surged in brightness by as much as
            1 millionfold in the last million years. That would be enough for a jet to punch into the
            halo of material that surrounds the galaxy.
            This Hubble image of Galaxy NGC 1068, shows
            a similar scenario occurring.
            Previous observations by Hubble and other telescopes found evidence that the Milky Way’s
            black hole had an outburst about 2-4 million years ago that was energetic enough to create
            an immense pair of bubbles towering above our galaxy that glow in gamma-rays.
            Hubble was used to see how fast the bubbles were expanding and what they were made of.
            Hubble later found that the burst was so powerful that it lit up a gaseous structure as far
            away as 200,000 light-years from the galactic center.
            Called the Magellanic stream, seen here in pink,
            this gas is still glowing from that event even today.
            The residual jet feature is close enough to the black hole that it would become much more
            prominent only a few decades after the Milky Way’s black hole powers up again.
            Whenever that does actually end up happening, it’s sure to be quite a spectacular show!
            [ MUSIC ]`
        ]
    }

  return (
    <Layout>
        <div style={{width:'100%'}}>
            <h1>{info.title}</h1>
            <Star />
            <Vid videoLink={info.link}/>
            <Accordion flush>
                <Accordion.Item eventKey={1} key={1}>
                        <Accordion.Header>Transcript</Accordion.Header>
                        <Accordion.Body>
                                {info.transcript ? 
                                    info.transcript.map( p => <p style={{textAlign:'start'}}>{p}</p>)
                                    : 
                                    null
                                }
                        </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <p style={{textAlign:'start', margin:'1em 0'}}>{info.internalText}</p>
            <p>Learn more at <a href='https://svs.gsfc.nasa.gov/14042' target='_blank' rel="noreferrer">NASA's Goddard Media Studios</a></p>
        </div>
    </Layout>
  )
}

export default MonsterBlackhole